import * as React from "react";
import SEO from "../../components/seo";

import { StaticImage } from "gatsby-plugin-image";
import ContactUs from "../../components/ContactUs";
import MoreProjects from "../../components/MoreProjects";
import { useEffect, useState } from "react";

const DriftPage = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  } else {
    return (
      <div className="">
        <SEO title="Drift - Spring" lang="en" />

        <div className="hidden md:block w-full -mt-24 left-0 -mb-0 right-0 h-[24rem] relative ">
          <StaticImage
            draggable="false"
            className="w-full h-full absolute"
            src="../../images/projects/drift/headerBg.png"
            alt="Drift App"
            placeholder="blurred"
          />
          <div className="h-full w-full flex justify-center items-center absolute bottom-0">
            <StaticImage
              draggable="false"
              className="h-full max-w-7xl  "
              src="../../images/projects/drift/header.png"
              alt="Drift App"
              placeholder="blurred"
            />
          </div>
        </div>

        <div className="max-w-7xl px-6 lg:px-8 mx-auto  text-black100 font-inter overflow-x-hidden ">
          <div className="w-full font-inter mt-9 mb-20 md:my-20 text-left">
            <h1 className="text-4xl md:text-4xl lg:text-5xl font-semibold ">
              Drift combines meditation & with popular{" "}
              <br className="hidden lg:block" /> focus methods to help a global
              audience <br className="hidden lg:block" /> improve their focus.
            </h1>
            <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-52 md:mt-8 mt-6 text-left xl:mx-32">
              <p className="md:w-1/2 max-w-md md:text-base">
                In a saturated market of focus apps, Drift needed to stand out
                from the rest. Based on the widely used Pomodoro technique,
                Drift adds an extra level of customisation, with Focus Presets,
                as well as the ability to create custom focus sessions to suit
                user needs.
              </p>
              <p className="md:text-base ">
                - UI Design <br />- UX Design <br />- Visual Identity <br />-
                iOS Development <br />- Branding
              </p>
            </div>
          </div>
          {/* IMAGES */}
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/drift/1o1.png"
                alt="Typography"
                placeholder="blurred"
              />
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/drift/1o2.png"
                alt="Explore Properties"
                placeholder="blurred"
              />
            </div>
            <StaticImage
              draggable="false"
              className="w-full"
              src="../../images/projects/drift/2.png"
              alt="Property Details"
              placeholder="blurred"
            />
            <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/drift/3o1.png"
                alt="Group Conversation"
                placeholder="blurred"
              />
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/drift/3o2.png"
                alt="Groups Features"
                placeholder="blurred"
              />
            </div>

            <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <StaticImage
                className="md:w-1/2"
                src="../../images/projects/drift/4o1.png"
                alt="Lendobox"
                placeholder="blurred"
              />
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/drift/4o2.png"
                alt="Darkmode Groups"
                placeholder="blurred"
              />
            </div>
          </div>
          <MoreProjects excludeProject="drift" />
          <ContactUs />
        </div>
      </div>
    );
  }
};

export default DriftPage;
